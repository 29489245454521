import Image from 'next/image'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { FALLBACK_IMAGES } from '@cms/utils/constants'
import { getImageWithFallback, getSmartCropBackgroundImageLoader } from '@cms/utils/utils'
import type { SmallHeroTeaserProps } from '@knauf-group/ct-designs/components/core/SmallHeroTeaser'
import { SmallHeroTeaser } from '@knauf-group/ct-designs/components/core/SmallHeroTeaser'
import type { TypeContentSmallHeroTeaserSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { useTheme } from '@mui/material/styles'

const SmallHeroTeaserWrapper: React.FC<ContentEntry<TypeContentSmallHeroTeaserSkeleton>> = ({
  ...props
}) => {
  const theme = useTheme()
  const { fields } = props
  const { devicePixelRatio } = useDevicePixelRatio()
  // imageWithFallback should only be included if image is set in Contentful
  const image =
    fields?.image?.[0] && getImageWithFallback(fields?.image?.[0], FALLBACK_IMAGES.BACKGROUND)

  const componentProps: SmallHeroTeaserProps = {
    headline: fields?.headline || '',
    text: fields?.text || null,
    nextImageComponent: Image,
    imageLoader: devicePixelRatio
      ? getSmartCropBackgroundImageLoader(
          CONTENTFUL_TYPES.CONTENT_SMALL_HERO_TEASER,
          theme.breakpoints.values,
          devicePixelRatio,
        )
      : undefined,
    image,
  }

  return <SmallHeroTeaser {...componentProps} />
}

export default SmallHeroTeaserWrapper
